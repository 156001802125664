<template>

  <div id="course-page-header" class="mx-auto">
    <StudentCourseDashboard v-if="userData.roleId == 1"></StudentCourseDashboard>
    <!-- <BoxPlot></BoxPlot> -->
    <!-- <BarChart></BarChart> -->
    <!-- <ScatterHeatChart></ScatterHeatChart> -->
    <!-- <BoxPlot></BoxPlot> -->
    <!-- <LineChart></LineChart> -->
    <!-- ScatterHeatChart></ScatterHeatChart> -->
    <!-- <div class="course-container" style="padding: 0px 10px;">
      <div class="row d-inline-block mx-1 ">
        <div class="course-card card card-shadow">
          <p class ="col-sm-3 mr-auto d-inline text-dark mt-2 mb-0 pl-0"> Class Average </p>
          <p class ="indicator-val mb-0 text-center"> {{classAvg}}</p>
          <p class ="indicator-unit text-end"> % </p>
        </div>
        <div class="row d-inline-block mx-1 ">
          <div class="course-card card card-shadow">
            <p class ="col-sm-3 mr-auto d-inline text-dark mt-2 mb-0 pl-0"> Class Median</p>
            <p class ="indicator-val mb-0 text-center"> {{classMed}}</p>
            <p class ="indicator-unit text-end"> % </p>
          </div>
        </div>
        <div class="row d-inline-block mx-1 ">
          <div class="course-card card card-shadow">
            <p class ="col-sm-3 mr-auto d-inline text-dark mt-2 mb-0 pl-0"> Class Deviation </p>
            <p class ="indicator-val mb-0 text-center"> {{classStd}}</p>
            <p class ="indicator-unit text-end"> % </p>
          </div>
        </div>
        <div class="row d-inline-block mx-1 ">
          <div class="course-card card card-shadow">
            <p class ="col-sm-3 mr-auto d-inline text-dark mt-2 mb-0 pl-0"> Class Variance</p>
            <p class ="indicator-val mb-0 text-center"> {{classVar}}</p>
            <p class ="indicator-unit text-end"> % </p>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    <TeacherCourseDashboard v-else></TeacherCourseDashboard>
  </div>
</template>

<script>
import StudentCourseDashboard from './StudentCourseDashboard.vue';
import TeacherCourseDashboard from './TeacherCourseDashboard.vue';
// import LineChart from './LineChart.vue';
// import BarChart from './BarChart.vue';
// import ScatterHeatChart from './ScatterHeatChart.vue';

export default {
  name: 'CourseDashboard',
  components: {
    StudentCourseDashboard,
    TeacherCourseDashboard,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      indData: null,
      classAvg: null,
      classMed: null,
      classStd: null,
      classVar: null,
      class1Q: null,
      class3Q: null,
      params: [],
      interval: null,
      intervals: [],
      refresh: null,
      paramValue: '-',
      paramUnits: '-',
      paramName: '-',
      userId: null,
      classId: null,
    };
  },
  methods: {
    getUserData() {
      const { userData } = this.$store.state;
      this.userId = userData.id;
      console.log(this.userId);
    },
  },
  mounted() {
    console.log(this.$store.state.userData);
  },
};
</script>

<style scoped>
#course-page-header {
  max-width: 1200px;
}

.course-card {
  border-left-style: solid;
  border-color:  coral;
  border-width: 0.5rem 0px 0px 0px;
  width:  22rem;
  color: var(--primary-color);
  margin-bottom: 30px;
}
.dot {
  height: 1.5em;
  width: 1.5em;
  background-color: #7CFC00;
  border-radius: 50%;
  display: inline-block;
  text-align: right;

}
.indicator-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.indicator-val {
  font-size: 1.5rem;
  font-weight: bold;
}
.indicator-unit {
  font-size: 1rem;
  font-style: italic;
}
.err {
  background-color: #ff0000 !important;
}

blink, .blink {
  -webkit-animation: blink 1s step-end infinite;
  -moz-animation: blink 1s step-end infinite;
  -o-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}
@-webkit-keyframes blink {
  67% { opacity: 0 }
}

@-moz-keyframes blink {
  67% { opacity: 0 }
}

@-o-keyframes blink {
  67% { opacity: 0 }
}

@keyframes blink {
  67% { opacity: 0 }
}

</style>
