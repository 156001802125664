<template>
  <div id="boxplot-cont">
    <div class="chart-bg mx-2">
      <svg :class="[darkMode ? 'background-dark' : 'bg-white']"
      v-show="!resizeToggle" id="boxplot"
      :viewBox="`0 0 ${width} ${height}`">
        <g class="line" :width="width" :height="height"></g>
        <g class="box" :width="width" :height="height"></g>
        <g class="medline" :width="width" :height="height"></g>
        <circle v-if="studentPoint" r="12" :fill="dotColor" :cx="x(studentPoint)" :cy="height / 2">
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import * as d3 from 'd3';
// import $ from 'jquery';
import 'bootstrap';

export default {
  name: 'BoxWhiskers',
  props: ['dataSet', 'studentPoint', 'darkMode', 'hFactor'],
  computed: {
    y() {
      return d3.scalePoint()
        .range([this.margin.left, this.width - this.margin.right])
        .padding(0.5)
        .domain(this.dataSet.map((d) => d.title));
    },
    x() {
      return d3.scaleLinear()
        .domain([0, 100])
        .range([this.margin.left, this.width - this.margin.right]);
    },
    boxWidth() {
      return this.height * parseFloat(this.hFactor);
    },
    dotColor() {
      return this.darkMode ? '#FCF0D9' : '#0067A5';
    },
  },
  data() {
    return {
      dot: null,
      parseDate: null,
      height: 100,
      width: 200,
      boxCenter: 100,
      jitterWidth: 50,
      pathLength: 0,
      margin: ({
        top: 30,
        right: 30,
        bottom: 30,
        left: 60,
      }),
      userId: null,
      classId: null,
      resizeTimeout: null,
      resizeToggle: false,
      pointerX: '',
      pointerY: 0,
      interval: '',
      panelTitle: '',
      param1: '',
      param2: '',
      // intervals: [],
      params: [],
      // selectInt: 'DAY',
      xTitle: '',
      yTitle: '',
    };
  },
  methods: {
    calcStats() {
      // const dataSorted = this.dataSet.sort(d3.ascending);
      // const q1 = d3.quantile(dataSorted, 0.25);
      // const median = d3.quantile(dataSorted, 0.5);
      // const q3 = d3.quantile(dataSorted, 0.75);
      // const interQuantileRange = q3 - q1;
      // const min = d3.min(dataSorted);
      // const max = d3.max(dataSorted);
      const {
        q1, median, q3, min, max,
      } = this.dataSet;

      d3.select('svg#boxplot')
        .select('g.line')
        .append('line')
        .attr('y1', this.height / 2)
        .attr('y2', this.height / 2)
        .attr('x1', this.x(0))
        .attr('x2', this.x(100))
        .style('stroke', '#B0B3C5')
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', 5);
      d3.select('svg#boxplot')
        .select('g.line')
        .append('line')
        .attr('y1', this.height / 2)
        .attr('y2', this.height / 2)
        .attr('x1', this.x(min))
        .attr('x2', this.x(max))
        .style('stroke', '#78909C')
        .attr('stroke-width', 4);
      // .append('svg')
      d3.select('svg#boxplot')
        .select('g.box')
        .append('rect')
        .attr('y', (this.height - this.boxWidth) / 2)
        .attr('x', this.x(q1))
        .attr('width', this.x(q3) - this.x(q1))
        .attr('height', this.boxWidth)
        .style('fill', '#B0B3C5');
      // .append('svg')
      d3.select('svg#boxplot')
        .select('g.medline')
        .append('line')
        .attr('y1', (this.height + this.boxWidth) / 2)
        .attr('y2', (this.height - this.boxWidth) / 2)
        .attr('x1', this.x(median))
        .attr('x2', this.x(median))
        .attr('stroke', '#78909C')
        .attr('stroke-width', 4);
      d3.select('svg#boxplot')
        .select('g.medline')
        .append('line')
        .attr('y1', (this.height + this.boxWidth) / 2)
        .attr('y2', (this.height - this.boxWidth) / 2)
        .attr('x1', this.x(min))
        .attr('x2', this.x(min))
        .attr('stroke', '#78909C')
        .attr('stroke-width', 4);
      d3.select('svg#boxplot')
        .select('g.medline')
        .append('line')
        .attr('y1', (this.height + this.boxWidth) / 2)
        .attr('y2', (this.height - this.boxWidth) / 2)
        .attr('x1', this.x(max))
        .attr('x2', this.x(max))
        .attr('stroke', '#78909C')
        .attr('stroke-width', 4);
    },
    updateDimensions() {
      // this.height = document.getElementById('boxplot-cont').offsetHeight;
      this.height = 200;
      this.width = document.getElementById('boxplot-cont')?.offsetWidth;
    },
    getUserData() {
      const { userData } = this.$store.state;
      this.userId = userData.id;
    },
  },
  beforeMount() {
    this.dot = d3.line()
      .x((d) => this.x(d[0]))
      .y((d) => this.y(d[1]));
  },
  mounted() {
    this.classId = this.$route.params.cid;
    this.getUserData();
    this.updateDimensions();
    this.calcStats();
    window.addEventListener('resize', () => {
      // this.resizeToggle = true;
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = null;
      }
      this.resizeTimeout = setTimeout(() => {
        this.updateDimensions();
        this.resizeToggle = false;
      }, 1000);
    });
  },
};
</script>

<style scoped>

</style>
