<template>
  <div style="height: 100%;" :id="`spark-container${index}`" class="pt-0 text-center" >
    <div class="course-container" style="padding: 0 10px;">
      <h5 class="mx-2 mt-5 mb-2 text-start">
        <i class="material-icons align-middle text-acc1"
        style="font-size: 3rem;">
          published_with_changes
        </i>
        <b class="text-pri"> My Progress </b>
      </h5>
      <div class="progress mx-2 mb-5" style="height: 1.5rem;">
        <div class="progress-bar background-dark text-light fs-6 justify-center" role="progressbar"
         aria-valuemin="0" aria-valuemax="100" :style="`width:${cProg}%`">
          {{cProg}}% Complete
        </div>
      </div>
     <!--  <div class="row d-inline-block mx-1 ">
        <div class="course-card(Y) card card-shadow">
          <p class ="mr-auto d-inline text-dark mt-2 mb-0 pl-0">Progress</p>
          <p class ="indicator-val mb-0 text-center"> {{cProg}}</p>
          <p class ="indicator-unit text-end"> % </p>
        </div>
      </div> -->
      <hr class="mx-2"/>
      <h5 class="mx-2 mt-2 mb-2 text-start">
        <i class="material-icons align-middle text-acc1"
        style="font-size: 3rem;">
          track_changes
        </i>
        <b class="text-pri"> My Score </b>
      </h5>
      <div class="card mx-2 mb-2 background-dark">
        <div class="row mx-0">
          <div class="col-md-2">
            <div class="text-start mt-4 ms-2">
              <Progress strokeColor="#ecab32" :value="studentScore"
              :radius="55" :strokeWidth="10" class="text-light">
                <template class="text-center" v-slot:footer>
                  <p class="text-center"> <b>Total</b> </p>
                </template>
              </Progress>
            </div>
          </div>
          <div class="col">
            <BoxWhiskers :data-set="dataSet" :student-point="studentScore"
            :h-factor="0.35" :dark-mode="true" v-if="dataSet"></BoxWhiskers>
          </div>
        </div>
      </div>
      <div class="row mx-2 pb-4">
        <div class="course-card bg-accent-1-tint1 card col me-1">
          <Progress strokeColor="#00397A" :value="hwAvg" :radius="45" :strokeWidth="8">
            <template v-slot:footer>
              <b>Homeworks</b>
            </template>
          </Progress>
        </div>
        <div class="course-card bg-accent-1-tint1 card col mx-1">
          <Progress strokeColor="#00397A" :value="quizAvg" :radius="45" :strokeWidth="8">
            <template v-slot:footer>
              <b>Quizzes</b>
            </template>
          </Progress>
        </div>
        <div class="course-card bg-accent-1-tint1 card col ms-1">
          <Progress strokeColor="#00397A" :value="examAvg" :radius="45" :strokeWidth="8">
            <template v-slot:footer>
              <b>Exams</b>
            </template>
          </Progress>
        </div>
      </div>
      <hr class="mx-2"/>
      <h5 class="mx-2 mt-4 text-start">
        <i class="material-icons align-middle text-acc1"
        style="font-size: 3rem;">
          tungsten
        </i>
        <b class="text-pri"> Insights </b>
      </h5>
      <div class="card mx-2 mb-2 p-2 background-dark">
        <p class="text-start text-light mt-2">
            Your performance over the last 5 assignments has
            <b class="text-danger" >dropped </b>:
        </p>
        <div class="row mx-0 bg-accent-1-tint1 py-3 rounded">
          <div class="col" v-for="score, ind in recentScores" :key="ind">
            <i :id="ind"
            class="material-icons align-middle" style="font-size: 3rem;">
              <span v-if="score >= passThreshold" class="text-success">
                check_circle
              </span>
              <span v-else class="text-warning"> error </span>
            </i>
          </div>
        </div>
      </div>
      <div class="card mx-2 mb-2 p-2 bg-accent-1-tint1">
        <p class="text-start text-pri mt-2">
            You seem to have
            <b class="text-danger">trouble</b>
            with the following topics:
        </p>
        <div class="row mx-0 bg-light py-3 rounded">
          <div class = "col-md-1" v-for="topic, ind in topics" :key="ind">
            <div class="rounded-pill bg-dark text-light"> {{topic.topic}}</div>
          </div>
        </div>
      </div>
      <div class="card mx-2 mb-2 p-2 background-dark">
        <p class="text-start text-light mt-2">
          On average, you spend
          <b class="text-danger">less</b>
          time on each reading assignment than recommended
        </p>
        <div class="progress mb-3" style="height: 1.5rem;">
          <div class="progress-bar bg-accent-1 fs-6 justify-center" role="progressbar"
           aria-valuemin="0" aria-valuemax="100"
           :style="`width: ${(Math.random()*100).toFixed(2)}%`">
            {{(Math.random()*10).toFixed(2)}} minutes
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'bootstrap';
import Progress from 'easy-circular-progress';
import BoxWhiskers from './BoxWhiskers.vue';

export default {
  name: 'StudentCourseDashboard',
  props: ['index', 'card'],
  components: {
    Progress,
    BoxWhiskers,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      indData: null,
      classAvg: null,
      classMed: null,
      classStd: null,
      classVar: null,
      class1Q: null,
      class3Q: null,
      total: null,
      hwAvg: null,
      quizAvg: null,
      examAvg: null,
      studentScore: null,
      params: [],
      interval: null,
      intervals: [],
      refresh: null,
      paramValue: '-',
      paramUnits: '-',
      paramName: '-',
      userId: null,
      classId: null,
      dataSet: null,
      recentScores: [],
      cProg: 0,
      passThreshold: 60, // Threshold for heatmap
      topics: [],
    };
  },
  methods: {
    getTeachData() {
      axios.get('/api/getTeachData', {
        params: {
          uid: this.userData.id,
          cid: this.classId,
        },
      })
        .then((res) => {
          [this.indData] = res.data.paramData;
          this.total = this.indData.total;
          this.classAvg = parseFloat((this.indData.mean / this.total) * 100).toFixed(2);
          this.classMed = parseFloat((this.indData.median / this.total) * 100).toFixed(2);
          this.classStd = parseFloat((this.indData.std / this.total) * 100).toFixed(2);
          this.classVar = parseFloat((this.indData.std / this.total) ** 2 * 10000).toFixed(2);
          this.class1Q = parseFloat((this.indData.quart1 / this.total) * 100).toFixed(2);
          this.class3Q = parseFloat((this.indData.quart3 / this.total) * 100).toFixed(2);
        });
    },
    getBoxData() {
      axios.get('/api/getBoxData', {
        params: {
          uid: this.userId,
          cid: this.classId,
        },
      })
        .then((res) => {
          this.dataSet = res.data;
          console.log(this.dataSet);
        });
    },
    getStudData() {
      axios.get('/api/getStudData', {
        params: {
          uid: this.userData.id,
          cid: this.classId,
        },
      })
        .then((res) => {
          this.hwAvg = parseFloat(res.data.hwAvg).toFixed(2);
          this.quizAvg = parseFloat(res.data.quizAvg).toFixed(2);
          this.examAvg = parseFloat(res.data.examAvg).toFixed(2);
          this.studentScore = parseFloat(res.data.totScore).toFixed(2);
          this.getBoxData();
          this.getRecentScores();
          this.getWeakTopics();
        });
    },
    getWeakTopics() {
      axios.get('/api/getWeakTopicsStudent', {
        params: {
          cid: this.classId,
        },
      })
        .then((res) => {
          this.topics = res.data;
        });
    },
    getAllModuleDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/module/alldetails', {
        params: {
          courseId: this.classId,
          studentId: this.userData.id,
        },
      })
        .then((response) => {
          console.log(response.data);
          const totMods = response.data.length;
          const completeMods = response.data.filter((obj) => obj.completed === 1);
          this.cProg = ((completeMods.length / totMods) * 100).toFixed(2);
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    getRecentScores() {
      axios.get('/api/getRecentScores', {
        params: {
          uid: this.userData.id,
          cid: this.classId,
        },
      })
        .then((res) => {
          this.recentScores = res.data.recentScores;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.classId = this.$route.params.cid;
    if (this.userData.roleId === 2) {
      this.getTeachData();
    } else {
      this.getStudData();
      this.getAllModuleDetails();
    }
    if (this.refresh) {
      clearInterval(this.refresh);
      this.refresh = null;
    }
    this.refresh = setInterval(() => {
      // what's this get param data ???, the method doesn't exist
      // this.getParamData();
    }, 600000);
  },
};
</script>

<style scoped>
.course-card {
  width:  22rem;
  color: var(--primary-color);
  padding: 1rem 0;
}
.dot {
  height: 1.5em;
  width: 1.5em;
  background-color: #7CFC00;
  border-radius: 50%;
  display: inline-block;
  text-align: right;

}
.indicator-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.indicator-val {
  font-size: 1.5rem;
  font-weight: bold;
}
.indicator-unit {
  font-size: 1rem;
  font-style: italic;
}
.err {
  background-color: #ff0000 !important;
}

blink, .blink {
  -webkit-animation: blink 1s step-end infinite;
  -moz-animation: blink 1s step-end infinite;
  -o-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}
@-webkit-keyframes blink {
  67% { opacity: 0 }
}

@-moz-keyframes blink {
  67% { opacity: 0 }
}

@-o-keyframes blink {
  67% { opacity: 0 }
}

@keyframes blink {
  67% { opacity: 0 }
}

</style>
