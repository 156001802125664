<template>
  <div>
    <h5 class="mt-5 mx-2 mb-2 text-start">
      <i class="material-icons align-middle text-acc1" style="font-size:3rem;">
        groups
      </i>
      <b class="text-pri"> Class Performance</b>
    </h5>
    <div class="row mb-5 align-items-center">
      <div class="col-md-4">
        <div class="card bg-accent-1-tint1 py-5">
          <div class="card-body text-center">
            <div>
              <span v-if="activity" class="display-2 text-dark">{{ activity.total_students }}</span>
              <span v-if="!activity" class="fs-3 fw-light text-muted"> / - students</span>
              <span v-else class="fs-3 fw-light text-muted">
                 / {{ activity.total_students }} students
              </span>
            </div>
            <span class="fs-6 text-uppercase">Active in the last week</span>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card background-dark py-1">
          <div class="card-body">
            <div class="row">
              <div class="col-3 d-flex align-items-center">
                <Progress v-if="dataset" strokeColor="#ecab32" :value="dataset.median"
                :radius="55" class="text-light"
                :strokeWidth="10">
                  <template v-slot:footer>
                    <p class="text-center text-uppercase fw-light fs-6">
                      <b>median score</b>
                    </p>
                  </template>
                </Progress>
              </div>
              <div class="col-9">
                <BoxWhiskers :data-set="dataset" :student-point="null"
                :dark-mode="true" h-factor="0.3" v-if="dataset"></BoxWhiskers>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mx-2"/>
    <h5 class="mt-2 mx-2 mb-2 text-start">
      <i class="material-icons align-middle text-acc1" style="font-size:3rem;">
        tungsten
      </i>
      <b class="text-pri">Insights</b>
    </h5>
    <div class="row mx-0 mb-5 justify-content-around">
      <div class="card p-2 col-md-12 bg-accent-1-tint1">
        <div class="card-body">
          <div class="card text-dark mb-3 bg-white">
            <div class="card-body">
              <h4 class="card-title mb-4">Looks like students are having trouble with</h4>
              <div v-if="!topics" class="rounded-pill bg-placeholder w-100 text-center">
                Loading...
              </div>
              <div v-else class="d-flex justify-content-evenly">
                <div class="rounded-pill bg-pri text-center py-1 px-3 fs-5"
                v-for="topic, idx in topics" :key=idx>
                  {{ topic.topic }}
                </div>
              </div>
            </div>
          </div>
          <div class="card text-dark bg-white">
            <div class="card-body">
              <h4 class="card-title mb-4">Trends</h4>
              <ul class="fs-5">
                <li>Students are completing the course in sequence</li>
                <li>Performance is declining as students progress through the course</li>
                <li>Students spend less time than expected on readings</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mx-2"/>
    <h5 class="mt-2 mx-2 mb-2 text-start">
      <i class="material-icons align-middle text-acc1" style="font-size:3rem;">
        double_arrow
      </i>
      <b class="text-pri"> Class Progress</b>
    </h5>
    <div class="row mx-0 mb-5">
      <div class="card col-md-12 bg-accent-1-tint1">
        <div class="card-body">
          <div class="mb-3" v-for="mod, idx in progress" :key="idx">
            Module {{ mod.seq + 1 }}: {{ mod.module }}
            <div class="progress">
              <div class="progress-bar bg-pri" role="progressbar"
              :style="`width: ${mod.completionPct}%`"
              :aria-valuenow="mod.completionPct" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Progress from 'easy-circular-progress';
import BoxWhiskers from './BoxWhiskers.vue';

export default {
  name: 'TeacherCourseDashboard',
  components: {
    Progress,
    BoxWhiskers,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      indData: {
        median: null,
      },
      userId: null,
      classId: null,
      dataset: null,
      topics: null,
      progress: [],
      activity: null,
    };
  },
  methods: {
    getTeachData() {
      axios.get('/api/getTeachData', {
        params: {
          uid: this.userData.id,
          cid: this.classId,
        },
      })
        .then((res) => {
          [this.indData] = res.data.paramData;
          this.total = this.indData.total;
          this.dataset = {
            q1: ((this.indData.q1 / this.indData.total) * 100).toFixed(2),
            q3: ((this.indData.q3 / this.indData.total) * 100).toFixed(2),
            median: ((this.indData.median / this.indData.total) * 100).toFixed(2),
            min: ((this.indData.min / this.indData.total) * 100).toFixed(2),
            max: ((this.indData.max / this.indData.total) * 100).toFixed(2),
          };
          console.log(this.indData);
        });
      axios.get('/api/getWeakTopicsTeacher', {
        params: {
          cid: this.classId,
        },
      })
        .then((res) => {
          this.topics = res.data;
        });
      axios.get('/api/getCourseCompletionTeacher', {
        params: {
          cid: this.classId,
        },
      })
        .then((res) => {
          this.progress = res.data;
        });
      axios.get('/api/getCourseActivityTeacher', {
        params: {
          cid: this.classId,
        },
      })
        .then((res) => {
          this.activity = res.data;
        });
    },
  },
  mounted() {
    this.classId = this.$route.params.cid;
    this.getTeachData();
  },
};
</script>

<style scoped>

.bg-placeholder {
  background-color: #838996;
}
</style>
